import HomeBanner from "@/components/home/homeBanner";
import ContactForm from "@/components/contactForm";
import LayoutHome from "@/layout/layoutHome";
import Testimonials from "@/components/home/testimonials";
import Welcome from "@/components/home/welcome";

// import loadFrames from "../scripts/iframe-defer";
import { companyInfoQuery, divisionQuery, pageQuery } from "../lib/queries";
import { getClient } from "../lib/sanity.server";
import {
  companyInfoQueryResponse,
  divisionQueryResponse,
  pageQueryResponse,
} from "@/lib/sanityDangerousTypes";

export default function Index({
  companyInfo,
  division,
  page,
}: {
  companyInfo: companyInfoQueryResponse;
  division: divisionQueryResponse;
  page: pageQueryResponse;
}) {
  const { mapEmbedUrl } = companyInfo || {};
  const { people } = division || {};
  const { testimonials } = page || {};

  // useEffect(() => {
  //   import("../scripts/lite-yt-embed");
  //   loadFrames();
  // });

  return (
    <LayoutHome bodyClass="" companyInfo={companyInfo} page={page}>
      <HomeBanner />
      <Welcome />
      <Testimonials testimonials={testimonials} />
      <ContactForm
        pageName="HomePage"
        companyInfo={companyInfo}
        people={people}
      >
        <h2 className="text-4xl font-bold tracking-tight text-gray-900 font-display">
          Let&apos;s talk about your project
        </h2>
      </ContactForm>
      <div className="w-full h-96 lg:h-[450]">
        <iframe
          allowFullScreen
          src={mapEmbedUrl}
          height="450"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          style={{ border: 0, height: "100%" }}
          title="Google Map embed of WhitneyCRE location"
          width="100%"
        />
      </div>
    </LayoutHome>
  );
}

export async function getStaticProps({ preview = false }) {
  const companyInfo = await getClient(preview).fetch(companyInfoQuery);
  const division = await getClient(preview).fetch(divisionQuery, {
    division: "brokerage",
  });
  const page = await getClient(preview).fetch(pageQuery, {
    slug: "home",
  });

  return {
    props: { companyInfo, division, page },
  };
}
