import * as React from "react";

import { clsx } from "clsx";
import { Combobox } from "@headlessui/react";
import Bounce from "react-spinners/BounceLoader";
import { Building, MapPin, Search, XIcon } from "lucide-react";
import { Button } from "./button";
import { cn } from "@/lib/utils";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  getPlacePredictions: (options: { input: string }) => void;
  loading: boolean;
  handleChange: (
    placeDetails: google.maps.places.PlaceResult | undefined
  ) => void;
  placePredictions: google.maps.places.AutocompletePrediction[];
  placesService: google.maps.places.PlacesService | null;
  home?: boolean;
};

const GoogleAutoComplete = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      getPlacePredictions,
      handleChange,
      home = false,
      loading,
      placePredictions,
      placesService,
      value,
      ...props
    },
    ref
  ) => {
    const [isDirty, setIsDirty] = React.useState(false);
    const [selectedPrediction, setSelectedPrediction] =
      React.useState<google.maps.places.AutocompletePrediction>();

    React.useEffect(() => {
      if (selectedPrediction && isDirty) {
        placesService?.getDetails(
          {
            placeId: selectedPrediction.place_id,
          },
          (placeDetails) => handleChange(placeDetails || undefined)
        );
      }
    }, [handleChange, isDirty, placesService, selectedPrediction]);

    const clearInput = () => {
      setIsDirty(false);
      setSelectedPrediction(undefined);
      handleChange(undefined);
    };

    return (
      <Combobox as="div" value={value} className={`w-full`}>
        <span
          data-slot="control"
          className={
            home
              ? "relative block h-11 w-full"
              : clsx([className, "relative block w-full"])
          }
        >
          <Combobox.Input
            className={
              home
                ? "w-full h-full rounded-full border-2 border-gray-300 pl-4 pr-12 focus-visible:ring-orange-500"
                : cn(
                    "flex h-11 w-full rounded-md border border-zinc-200 bg-white px-3 py-2 text-base ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-zinc-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-blue-800 dark:bg-zinc-950 dark:ring-offset-blue dark:placeholder:text-zinc-400 dark:focus-visible:ring-zinc-300",
                    className
                  )
            }
            onChange={(evt) => {
              if (evt.target.value === "") {
                clearInput();
              } else {
                setIsDirty(true);
                getPlacePredictions({
                  input: evt.target.value,
                });
              }
            }}
            onBlur={() => {
              if ((placePredictions?.length || 0) > 0 && isDirty)
                setSelectedPrediction(placePredictions?.[0]);
            }}
            autoComplete="off"
            ref={ref}
            {...props}
          />
          {loading && (
            <div className="absolute top-0 bottom-0 right-0 flex items-center justify-center pr-3">
              {/* Replace this with your actual loading indicator */}
              <Bounce color="orange" size={18} />
            </div>
          )}
          {value && (
            <div className="absolute top-0 bottom-0 right-0 flex items-center justify-center">
              {/* Replace this with your actual loading indicator */}
              <Button variant="ghost" onClick={() => clearInput()}>
                <XIcon className="w-4 h-4" />
              </Button>
            </div>
          )}
          {home && (
            <Button
              type="submit"
              size="icon"
              className="absolute -translate-y-1/2 bg-orange-500 rounded-full right-1 top-1/2 hover:bg-orange-600"
            >
              <Search className="w-4 h-4" />
              <span className="sr-only">Search</span>
            </Button>
          )}
        </span>
        <Combobox.Options className="absolute z-10 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {placePredictions?.map((place) => {
            const start = place.matched_substrings[0]?.offset || 0;
            const end =
              start +
              (place.matched_substrings[0]
                ? place.matched_substrings[0].length
                : 0);

            const beforeBold = place.description.substring(0, start);
            const bold = place.description.substring(start, end);
            const afterBold = place.description.substring(end);

            const type = place.types?.[0];
            return (
              <Combobox.Option
                key={place.place_id}
                value={place}
                onClick={() => {
                  setIsDirty(false);
                  setSelectedPrediction(place);
                }}
                className={clsx([
                  // Basic layout
                  "relative flex flex-row items-center w-full appearance-none px-[calc(theme(spacing[3.5])-1px)] py-2 sm:px-[calc(theme(spacing[3])-1px)] sm:py-3",

                  // Typography
                  "text-base text-zinc-950 placeholder:text-zinc-500 dark:text-white",

                  // Background color
                  "bg-transparent dark:bg-white/5 hover:bg-orange-50",

                  // Hide default focus styles
                  "focus:outline-none",
                ])}
              >
                {type === "premise" ||
                type === "street_address" ||
                type === "geocode" ? (
                  <Building className="w-5 h-5 mr-1 text-slate-600" />
                ) : (
                  <MapPin className="w-5 h-5 mr-1 text-slate-600" />
                )}
                <span className="align-bottom">
                  {beforeBold}
                  <span className="font-bold">{bold}</span>
                  {afterBold}
                </span>
              </Combobox.Option>
            );
          })}
        </Combobox.Options>
      </Combobox>
    );
  }
);
GoogleAutoComplete.displayName = "GoogleAutoComplete";

export { GoogleAutoComplete };
