import Image from "next/image";
import Slider from "@/components/react-slick";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { GoogleAutoComplete } from "../ui/google-autocomplete";
import { useState } from "react";
import { useRouter } from "next/router";
import { compressToEncodedURIComponent } from "lz-string";

const images = [
  {
    url: "https://d3w216np43fnr4.cloudfront.net/1920x1080/8388/123038/1.jpg",
    alt: "Asheville Skyline View",
  },
  {
    url: "https://d3w216np43fnr4.cloudfront.net/1920x1080/8388/123038/2.jpg",
    alt: "Sign outside the Whitney Commercial Real Estate offices",
  },
  {
    url: "https://d3w216np43fnr4.cloudfront.net/1920x1080/8388/123038/3.jpg",
    alt: "Sign outside the Whitney Commercial Real Estate offices",
  },
  {
    url: "https://d3w216np43fnr4.cloudfront.net/1920x1080/8388/123038/6.jpg",
    alt: "Aerial view of the Ridgefield Business Park in Asheville, NC",
  },
  {
    url: "https://d3w216np43fnr4.cloudfront.net/1920x1080/8388/123038/4.jpg",
    alt: "Sign outside the Whitney Commercial Real Estate offices",
  },
  {
    url: "https://d3w216np43fnr4.cloudfront.net/1920x1080/8388/123038/5.jpg",
    alt: "Front of the 1100 Ridgefield Blvd building",
  },
];

export default function HomeBanner() {
  const router = useRouter();

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: `${process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY}&loading=async`,
    debounce: 500,
    options: {
      types: ["geocode"],
      componentRestrictions: { country: "us" },
      locationBias: {
        center: { lat: 35.59528, lng: -82.55513 }, // Asheville, NC
        radius: 96560, // 60 miles in meters
      },
      input: "",
    },
  });

  const [placeAddress, setPlaceAddress] = useState("");

  const openSearch = (prediction: google.maps.places.PlaceResult) => {
    setPlaceAddress(prediction.formatted_address || "");

    router.push(
      `/search?placeAddress=${
        prediction.formatted_address
      }&placeComponents=${compressToEncodedURIComponent(
        JSON.stringify(prediction.address_components)
      )}`
    );
  };

  return (
    <section className="relative block w-full bg-blue">
      <div className="absolute inset-0 w-full h-full overflow-hidden">
        <Slider
          className="w-full h-full"
          speed={1000}
          autoplaySpeed={7000}
          autoplay
          fade
          dots={false}
          fullHeight
        >
          {images.map((image) => (
            <div key={image.url} className="w-full h-full">
              <Image
                alt={image.alt || ""}
                height="576"
                priority
                src={image.url || ""}
                width="1920"
                className="object-cover w-full h-full opacity-30"
              />
            </div>
          ))}
        </Slider>
      </div>
      <div className="relative flex flex-col items-center content-center justify-center w-full h-full gap-3 py-16 sm:px-16 md:py-32 lg:py-36 py-auto">
        <h2 className="max-w-4xl mx-auto text-5xl tracking-wide text-center uppercase font-display text-gray-50">
          Turning complex challenges into profitable opportunities Since 2001
        </h2>
        {/* <p className="max-w-5xl pt-3 mx-auto text-[14px] font-display tracking-[2px] text-center text-white uppercase">
          We help you buy, sell, lease, manage, improve, and renovate commercial
          real estate in Asheville and throughout the Western North Carolina
          region.
        </p> */}
        <div className="relative w-full max-w-2xl px-4 mt-6 md:mx-auto">
          <GoogleAutoComplete
            getPlacePredictions={getPlacePredictions}
            loading={isPlacePredictionsLoading}
            handleChange={(prediction) => {
              if (prediction) {
                openSearch(prediction);
              } else {
                setPlaceAddress("");
              }
            }}
            home
            placePredictions={placePredictions}
            placeholder="Search by address, city, county, state or zip code"
            placesService={placesService}
            // In order to keep this input uncontrolled and also prevent cascading re-renders, we need to make sure the value is never null or undefined
            // https://stackoverflow.com/questions/71835144/react-warning-a-component-is-changing-an-uncontrolled-input-to-be-controlled
            value={placeAddress || ""}
          />
        </div>
      </div>
    </section>
  );
}
