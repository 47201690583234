import { ReactNode, useEffect, useState } from "react";
import Image from "next/image";
import { pageQueryResponseTestimonial } from "@/lib/sanityDangerousTypes";
import Slider from "@/components/react-slick";

export default function Testimonials({
  testimonials,
}: {
  testimonials: pageQueryResponseTestimonial[] | undefined;
}) {
  const numTestimonials = testimonials?.length || 0;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const speed = 5000;

  useEffect(() => {
    const timer = setTimeout(() => {
      const newIndex =
        selectedIndex + 1 === numTestimonials ? 0 : selectedIndex + 1;
      setSelectedIndex(newIndex);
    }, speed);

    return () => clearTimeout(timer);
  }, [numTestimonials, selectedIndex]);

  if (!testimonials) return null;

  return (
    <section className="relative block w-full overflow-hidden bg-blue">
      <div className="absolute inset-0 w-full h-full">
        <Image
          alt="testimonial-background-image"
          height="576"
          priority={selectedIndex === 0}
          src={"https://dl6bglhcfn2kh.cloudfront.net/phpMzymZQ.jpg"}
          width="1920"
          className="object-cover w-full h-full opacity-20"
        />
      </div>

      <Slider
        className="relative flex items-center justify-center h-full max-w-4xl px-6 mx-auto mt-32 mb-24"
        speed={1000}
        autoplaySpeed={5000}
        autoplay
        dots
        appendDots={(dots: ReactNode) => (
          <div className="p-3 border border-green">
            <ul className=""> {dots} </ul>
          </div>
        )}
        customPaging={() => (
          <div className="w-2 h-2 rounded-full bg-white/80" />
        )}
        fade
      >
        {testimonials.map((testimonial) => (
          <div
            className="flex flex-col justify-end w-full h-full mb-4"
            key={testimonial._id}
            itemType="http://schema.org/Review"
          >
            <p
              className="font-serif text-3xl italic text-center text-white md:text-4xl font-extralight"
              itemProp="reviewBody"
            >
              {testimonial.body}
            </p>
            <div className="pt-6 text-xl font-light tracking-widest text-center text-white capitalize md:text-2xl font-display">
              <span itemProp="author">{testimonial.citation}</span>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
}
